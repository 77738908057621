// This is the scss entry file
import "../styles/index.scss";

// We can import Bootstrap JS instead of the CDN link, if you do not use
// Bootstrap, please feel free to remove it.
// import "bootstrap/dist/js/bootstrap.bundle";

// We can import other JS file as we like
import "../components/mobile-sidebar";
// Import Media Gallery
import PhotoSwipeLightbox from 'photoswipe/lightbox';
import 'photoswipe/style.css';


document.addEventListener('DOMContentLoaded', () => {
    if (!document.getElementById("gallery")) return;

    console.log("Initializing Media Gallery");
    let isInitializingPhotoswipe = true;

    const lightbox = new PhotoSwipeLightbox({
        gallery: '#gallery',
        children: 'a',
        pswpModule: () => import('photoswipe')
    });
    lightbox.on('uiRegister', function() {
        lightbox.pswp.ui.registerElement({
          name: 'custom-caption',
          order: 9,
          isButton: false,
          appendTo: 'root',
          html: 'Caption text',
          onInit: (el) => {
            el.style.opacity = "0";  // Start with 0 opacity so that when the gallery is opened, the caption is animated in along with the image. 
            var currSlideElement;

            // Hide the caption as soon as we close the gallery
            lightbox.pswp.on("closingAnimationStart", () => {
              currSlideElement = lightbox.pswp.currSlide.data.element;
              if (currSlideElement) {
                const caption = currSlideElement.dataset.pswpCaption;
                if (caption) {
                  el.style.opacity = 0;
                }
              }
            });

            // Display the caption, if needed, once the opening animation is finished
            lightbox.pswp.on("openingAnimationEnd", () => {
              currSlideElement = lightbox.pswp.currSlide.data.element;
              if (currSlideElement) {
                const caption = currSlideElement.dataset.pswpCaption;
                if (caption) {
                  el.style.opacity = 1;
                  isInitializingPhotoswipe = false;  // Set the flag so that subsequent changes will trigger hiding/displaying the caption
                }
              }
            });

            lightbox.pswp.on('change', () => {  
              currSlideElement = lightbox.pswp.currSlide.data.element;
              let captionHTML = '';
              if (currSlideElement) {
                const caption = currSlideElement.dataset.pswpCaption;
                const hiddenCaption = currSlideElement.querySelector('.hidden-caption-content');
                console.log(caption);
                if (caption) {
                  // get caption from Django
                  captionHTML = caption;
                } else if (hiddenCaption) {
                  // get caption from element with class hidden-caption-content
                  captionHTML = hiddenCaption.innerHTML;
                } else {
                  // get caption from alt attribute
                  captionHTML = currSlideElement.querySelector('img').getAttribute('alt');
                }
              }

              if (captionHTML == null || captionHTML == '') {
                el.style.opacity = "0";  // Hide the caption element when there isn't a caption to display
              } else {
                if (!isInitializingPhotoswipe) {  // This flag is to ensure the caption isn't displayed before the opening animation is finished, in case the opening image has a caption
                  el.style.opacity = "1";  // Make sure the caption element displays when there is a caption when changing. afterInit will handle displaying the cpation if it's being initialized.
                }
                el.innerHTML = captionHTML || '';
              }
            });
          }
        });
    });
  lightbox.on('init', () => {
    isInitializingPhotoswipe = true; // Set the flag when PhotoSwipe is initialized
  });
  lightbox.init();
});